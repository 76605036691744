<template>
  <div
    class="page5"
    id="page5"
    :class="isDay ? 'bg-white-color' : 'bg-black-color'"
  >
    <div class="content">
      <div class="visible-xs title-we" :class="isDay ? 'c_black' : 'c_white'">
        <span>{{ $t(`home.four_nine`) }}</span>
      </div>
      <div class="map">
        <div
          class="botton hidden-xs"
          :class="isDay ? 'bg-C7C7C7' : 'bg-1B1C1F'"
        >
          <span :class="isDay ? 'c_black' : 'c_white'">{{
            $t(`home.four_eight`)
          }}</span>
        </div>

        <div class="title hidden-xs" :class="isDay ? 'c_black' : 'c_white'">
          <span>{{ $t(`home.four_nine`) }}</span>
        </div>

        <div class="contact1">
          <div class="tips" :class="isDay ? 'bg-0C66DF' : 'bg-221f1f'">
            <span class="tip">Asia-Pacific cooperation links</span>
            <span class="email" :class="isDay ? 'c_white' : 'c_150DF7'"
              >julianpte@163.com</span
            >
          </div>
          <img src="@/assets/img/Ellipse 35.png" alt="" />
        </div>

        <div class="contact2">
          <div class="tips" :class="isDay ? 'bg-0C66DF' : 'bg-221f1f'">
            <span class="tip">European and American Cooperation Contact</span>
            <span class="email" :class="isDay ? 'c_white' : 'c_150DF7'"
              >julianpte2023@gmail.com</span
            >
          </div>
          <img src="@/assets/img/Ellipse 36.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "pageFive",
  components: {},
  data() {
    return {
      curId: 0,
      timer: null,
      isDay: this.$store.state.isDay,
    };
  },
  created() {
  },
};
</script>

<style scoped lang='stylus' rel="stylesheet/stylus">
@media (min-width: 781px) {
  .page5 {
    padding: 50px 0;
  }

  .container {
    margin-top: 64px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .map {
    height: 600px;
    margin-top: 200px;
    box-sizing: border-box;
    background-image: url('@/assets/img/Frame 163.png');
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
    .botton {
      border-radius: 48px;
      width: 120px;
      height: 30px;
      padding: 6px 10px;
      text-align: center;

      span {
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Nunito Sans;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 30px; /* 166.667% */
        text-transform: capitalize;
      }
    }

    .title {
      margin-top: 28px;
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: Montserrat;
      font-size: 48px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-transform: capitalize;
      width: 450px;
    }

    .contact1 {
      position: relative;
      left: 600px;
      top: -160px;
      width: 300px;

      .tips {
        border-radius: 6px;
        background: #FFF;
        width: 300px;
        padding: 15px 50px;
        background-color: #221f1f;
        display: flex;
        flex-direction: column;
        justify-content: center;

        // align-items: center
        .email {
          font-family: Nunito Sans;
          font-size: 22px;
          margin-top: 8px;
        }

        .tip {
          color: #FFF;
          font-family: Nunito Sans;
          font-size: 20px;
        }
      }

      img {
        margin-top: 36px;
        margin-left: 60px;
      }
    }

    .contact2 {
      position: relative;
      left: 900px;
      top: -160px;
      width: 300px;

      .tips {
        border-radius: 6px;
        background: #FFF;
        width: 300px;
        padding: 15px 50px;
        background-color: #221f1f;
        display: flex;
        flex-direction: column;
        justify-content: center;

        // align-items: center
        .email {
          font-family: Nunito Sans;
          font-size: 22px;
          margin-top: 8px;
        }

        .tip {
          color: #FFF;
          font-family: Nunito Sans;
          font-size: 20px;
        }
      }

      img {
        margin-top: 36px;
        margin-left: 60px;
      }
    }
  }
}

@media (max-width: 780px) {
  .page5 {
    padding: 0.4rem 0;
  }

  .container {
    display: flex;
    flex-direction: column;
  }

  .title-we {
    font-size: 0.36rem;
    font-weight: bold;
    display: inline-block;
    line-height: 0.6rem;
  }

  .map {
    height: 4.5rem;
    margin-top: 0.2rem;
    box-sizing: border-box;
    background-image: url('@/assets/img/Frame 163-h5.png');
    background-size: 100% 100%;

    .contact1 {
      position: relative;
      left: 0.2rem;
      top: -0.2rem;
      width: 3rem;

      .tips {
        border-radius: 0.06rem;
        background: #FFF;
        width: 2rem;
        padding: 0.13rem 0.1rem;
        background-color: #221f1f;
        display: flex;
        flex-direction: column;
        justify-content: center;

        // align-items: center
        .email {
          font-family: Nunito Sans;
          font-size: 0.17rem;
          margin-top: 0.08rem;
        }

        .tip {
          color: #FFF;
          font-family: Nunito Sans;
          font-size: 0.13rem;
        }
      }

      img {
        margin-top: 0.36rem;
        margin-left: 0.36rem;
      }
    }

    .contact2 {
      // position: relative;
      margin-left: 0.6rem;
      width: 3rem;

      .tips {
        border-radius: 0.06rem;
        background: #FFF;
        width: 2rem;
        padding: 0.13rem 0.1rem;
        background-color: #221f1f;
        display: flex;
        flex-direction: column;
        justify-content: center;

        // align-items: center
        .email {
          font-family: Nunito Sans;
          font-size: 0.17rem;
          margin-top: 0.08rem;
        }

        .tip {
          color: #FFF;
          font-family: Nunito Sans;
          font-size: 0.13rem;
        }
      }

      img {
        margin-top: 0.36rem;
        margin-left: 0.6rem;
      }
    }
  }
}
</style>
