
const isOutside = function (){
  const lang = (navigator.language)?navigator.language:window.navigator.language
  console.log(lang)
  if (lang === 'zh-CN'){
    return false
  }else {
    return true
  }
}


// 获取ios版本号
const getIosVersion = function () {
  var str = navigator.userAgent.toLowerCase()
  var ver = str.match(/cpu iphone os (.*?) like mac os/)
  console.log(str, ver)
  return ver ? ver[1].replace(/_/g, ".") : "0"
}

// 版本号对比
const compareVersion = function(curV, reqV) {
  console.log(curV, reqV)
  if (curV && reqV) {
    //将两个版本号拆成数字
    var arr1 = curV.split('.'),
      arr2 = reqV.split('.');
    var minLength = Math.min(arr1.length, arr2.length),
      position = 0,
      diff = 0;
    //依次比较版本号每一位大小，当对比得出结果后跳出循环（后文有简单介绍）
    while (position < minLength && ((diff = parseInt(arr1[position]) - parseInt(arr2[position])) == 0)) {
      position++;
    }
    diff = (diff != 0) ? diff : (arr1.length - arr2.length);
    //若curV大于reqV，则返回true
    return diff >= 0;
  } else {
    //输入为空
    console.log("版本号不能为空");
    return false;
  }
}


const to = function (toEl) {
  document.getElementById(toEl).scrollIntoView({ behavior: "smooth" ,alignToTop:true});
}
export default {
  isOutside,
  getIosVersion,
  compareVersion,
  to
}
