<template>
  <div id="app">
    <Header></Header>
    <router-view></router-view>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "@/components/common/header";
import Footer from "@/components/common/footer";

export default {
  name: "App",
  data() {
    return {
      mobile: null,
    };
  },
  components: {
    Header,
    Footer,
  },
  mounted() {
    // this.isMobile()
    this.$store.commit("isMobile");
    let hours = new Date().getHours();
    if (hours >= 6 && hours <= 18) {
      console.log('nnnn');
      this.$store.state.isDay = true;
    } else {
      console.log('cccc');
      this.$store.state.isDay = false;
    }
  },
  methods: {},
};
</script>

<style lang="stylus">
@import './assets/css/reset.css';
@import './assets/css/common.css';
@import './assets/css/animate.css';

@media (min-width: 781px) {
  .hidden-xs {
    display: unset;
  }

  .visible-xs {
    display: none !important;
  }
}

@media (max-width: 780px) {
  .hidden-xs {
    display: none !important;
  }

  .visible-xs {
    display: unset;
  }
}
</style>
