const mutations = {
  isMobile(state){
    let flag = navigator.userAgent.match(/(phone|pad|pod|iphone|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Window Phone)/i)

    if (flag){
      //mobile
      state.isM = true
    }else {
      //pc
      state.isM = false
    }
  },
  changeIsDay(state){
    let hours = new Date().getHours();
    console.log('qqqqq');
    if(hours >=6 && hours <= 19){
      console.log('qqqqqqqq');
      state.isDay = false
    }else{
      console.log('wwww');
      state.isDay = false
    }
  }
}

export default mutations
