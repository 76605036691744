<template>
  <div
    class="page3"
    id="page3"
    :class="isDay ? 'bg-white-color' : 'bg-black-color'"
  >
    <div class="content">
      <div :class="isDay ? 'bg-C7C7C7' : 'bg-1B1C1F'">
        <h1 class="page-title page-title-center" :class="isDay ? 'c_black' : 'c_white'">
          {{ $t(`home.two_title`) }}
        </h1>
        <div class="fit-div"></div>
        <div class="second">
          <span class="second-title" :class="isDay ? 'c_black' : 'c_white'">{{
            $t(`home.two_second_title`)
          }}</span>
        </div>
        <div class="box" :class="isDay ? 'bg-C7C7C7' : 'bg-1B1C1F'">
          <div class="left">
            <img src="@/assets/img/Group 168.png" alt="" />
            <span class="video" :class="isDay ? 'c_0000cc' : 'c_ffffcc'">{{
              $t(`home.three_one`)
            }}</span>
            <span
              class="video-second"
              :class="isDay ? 'c_0000cc' : 'c_ffffcc'"
              >{{ $t(`home.three_two`) }}</span
            >
          </div>
          <div class="right">
            <img src="@/assets/img/Group 1 (2).png" alt="" class="hidden-xs"/>
            <img src="@/assets/img/p_1_h5.png" alt="" class="visible-xs"/>

          </div>
        </div>
        <div class="box" :class="isDay ? 'bg-C7C7C7' : 'bg-1B1C1F'">
          <div class="left">
            <img src="@/assets/img/Group 170.png" alt="" />
            <span class="video" :class="isDay ? 'c_0000cc' : 'c_ffffcc'">{{
              $t(`home.three_three`)
            }}</span>
            <span
              class="video-second"
              :class="isDay ? 'c_0000cc' : 'c_ffffcc'"
              >{{ $t(`home.three_four`) }}</span
            >
          </div>
          <div class="right">
            <img src="@/assets/img/Group 1 (1).png" alt="" class="hidden-xs"/>
            <img src="@/assets/img/p_2_h5.png" alt="" class="visible-xs"/>
          </div>
        </div>
        <div class="box" :class="isDay ? 'bg-C7C7C7' : 'bg-1B1C1F'">
          <div class="left">
            <img src="@/assets/img/Group 171.png" alt="" />
            <span class="video" :class="isDay ? 'c_0000cc' : 'c_ffffcc'">{{
              $t(`home.three_five`)
            }}</span>
            <span
              class="video-second"
              :class="isDay ? 'c_0000cc' : 'c_ffffcc'"
              >{{ $t(`home.three_six`) }}</span
            >
          </div>
          <div class="right">
            <img src="@/assets/img/Group 1.png" alt="" class="hidden-xs"/>
            <img src="@/assets/img/p_3_h5.png" alt="" class="visible-xs"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "pageThree",
  data() {
    return {
      isDay: this.$store.state.isDay,
    };
  },
};
</script>

<style scoped lang='stylus' rel="stylesheet/stylus">
// 电脑
@media (min-width: 781px) {
  .page3 {
    // padding: 50px 0;
  }

  .second {
    display: flex;
    justify-content: center;
    margin-top: -10px;
  }

  .content{
    h1{
      padding: 20px;
      margin: 0;
    }
  }

  .second-title {
    text-align: center;
    font-size: 26px;
  }

  .box {
    margin-top: 64px;
    border-radius: 48px;
    padding: 48px 0px;
    height: 498px;
    display: flex;
    flex-direction: row;

    .left-two {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      padding-left: 120px;
    }

    .left {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-left: 120px;

      img {
        width: 90px;
        height: 90px;
      }

      .video {
        margin-top: 48px;
        font-size: 36px;
        font-style: normal;
        font-weight: 700;
        line-height: 140%; /* 50.4px */
      }

      .video-second {
        margin-top: 18px;
        font-size: 18px;
        margin-right: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: 30px; /* 166.667% */
      }
    }

    .right {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      img {
        width: 464.606px;
        height: 386.431px;
      }
    }

    .right-two {
      flex: 1;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      img {
        width: 464.606px;
        height: 386.431px;
      }
    }

    .right-three {
      flex: 1;
      display: flex;
      justify-content: flex-start;
      align-items: flex-end;
      margin-bottom: -48px;

      img {
        width: 464.606px;
        height: 486.431px;
      }
    }
  }
}

@media (max-width: 780px) {
  .page3 {
    padding: 0.4rem 0;
  }

  .second {
    display: flex;
    justify-content: center;
    margin-top: -0.1rem;
  }

  .page-title {
    font-family: Montserrat;
    font-size: 0.36rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: left;
  }

  .fit-div {
    width: 0.5rem;
    height: 0.05rem;
    background-color: #6E6B6A;
    border-radius: 0.23rem;
    opacity: 0.4;
  }

  .second-title {
    text-align: left;
    font-size: 0.26rem;
    margin-top: 0.24rem;
    line-height: 0.4rem;
  }

  .box {
    margin-top: 0.64rem;
    border-radius: 0.48rem;
    display: flex;
    flex-direction: column;
    padding: 0.3rem 0.2rem;
    .left-two {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
    }

    .left {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;

      img {
        width: 0.9rem;
        height: 0.9rem;
      }

      .video {
        margin-top: 0.16rem;
        font-size: 0.18rem;
        font-style: normal;
        font-weight: 700;
        line-height: 140%; /* 50.4px */
      }

      .video-second {
        margin-top: 0.18rem;
        font-size: 0.15rem;
        margin-right: 0.10rem;
        font-style: normal;
        font-weight: 400;
        line-height: 0.3rem; /* 166.667% */
      }
    }

    .right {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 0.28rem;
      img {
        width: 3.2rem;
        height: 2.95rem;
      }
    }

    // .right-two {
    //   flex: 1;
    //   display: flex;
    //   justify-content: flex-start;
    //   align-items: center;

    //   img {
    //     width: 4.64rem;
    //     height: 3.86rem;
    //   }
    // }

    // .right-three {
    //   flex: 1;
    //   display: flex;
    //   justify-content: flex-start;
    //   align-items: flex-end;
    //   margin-bottom: -0.48rem;

    //   img {
    //     width: 4.64rem;
    //     height: 3.86rem;
    //   }
    // }
  }
}
</style>
