import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueWow from 'vue-wow'
import i18n from './common/i18n'
import common from './common/common'
Vue.use(VueWow)

Vue.prototype.$common = common
import 'normalize.css/normalize.css'

import VueAwesomeSwiper from 'vue-awesome-swiper'
// import 'Swiper/dist/css/swiper.css'
Vue.use(VueAwesomeSwiper)

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  i18n,
  store,
  router
}).$mount('#app')
