<template>
  <div class="page4" id="page4" :class="isDay ? 'bg-white-color': 'bg-black-color'">
    <div class="content">
        <h1 class="page-title page-title-center" :class="isDay ? 'c_black' : 'c_white'">{{ $t(`home.four_one`) }}</h1>
        <div class="fit-div"></div>
        <div class="second">
          <span class="second-title" :class="isDay ? 'c_black' : 'c_white'"> {{ $t(`home.four_two`) }}</span>
        </div>

      <div class="container">
        <div class="left">
          <span class="img-title">{{ $t(`home.four_three`) }}</span>
          <span class="img-detail">{{ $t(`home.four_four`) }}</span>
        </div>
        <div class="right">
          <span class="img-title">{{ $t(`home.four_five`) }}</span>
          <span class="img-detail">{{ $t(`home.four_seven`) }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "pageFour",
  data(){
    return{
      isDay:this.$store.state.isDay
    }
  }
};
</script>

<style scoped lang='stylus' rel="stylesheet/stylus">
// 电脑
@media (min-width: 781px) {
  .page4 {
    padding: 50px 0;
  }

  .second {
    display: flex;
    justify-content: center;
    margin-top: -10px;
  }

  .second-title {
    text-align: center;
    font-size: 26px;
    line-height: 40px;
  }

  .container {
    margin-top: 64px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .left {
      width: 45%;
      height: 600px;
      border-radius: 20px;
      box-sizing: border-box;
      background-image: url('@/assets/img/Mask group.png');
      background-size: 100% 100%;
      display: flex;
      justify-content: flex-end;
      padding: 0 0 80px 20px;
      flex-direction: column;

      .img-title {
        color: #FFF;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Montserrat;
        font-size: 36px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }

      .img-detail {
        margin-top: 24px;
        color: #FFF;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Nunito Sans;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 30px; /* 187.5% */
        margin-right: 10px;
      }
    }

    .right {
      width: 45%;
      height: 600px;
      border-radius: 20px;
      box-sizing: border-box;
      background-image: url('@/assets/img/Mask group-two.png');
      background-size: 100% 100%;
      display: flex;
      justify-content: flex-end;
      padding: 0 0 80px 20px;
      flex-direction: column;

      .img-title {
        color: #FFF;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Montserrat;
        font-size: 36px;
        font-weight: 700;
        font-style: normal;
        line-height: normal;
      }

      .img-detail {
        margin-right: 10px;
        margin-top: 24px;
        color: #FFF;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Nunito Sans;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 30px;
      }
    }
  }
}

@media (max-width: 780px) {
  .page4 {
    padding: 0.4rem 0;
  }

  .second {
    display: flex;
    justify-content: center;
    margin-top: -0.1rem;
  }

  .page-title {
    font-family: Montserrat;
    font-size: 0.36rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: left;
  }

  .fit-div {
    width: 0.5rem;
    height: 0.05rem;
    background-color: #6E6B6A;
    border-radius: 0.23rem;
    opacity: 0.4;
  }

  .second-title {
    text-align: left;
    font-size: 0.17rem;
    margin-top: 0.24rem;
    line-height: 0.4rem;
  }

  .container {
    margin-top: 0.2rem;
    display: flex;
    flex-direction: column;
    // justify-content: space-between;

    .left {
      margin-top: 0.2rem;
      height: 4rem;
      border-radius: 0.2rem;
      box-sizing: border-box;
      background-image: url('@/assets/img/Mask group.png');
      background-size: 100% 100%;
      display: flex;
      justify-content: flex-end;
      padding: 0 0 0.8rem 0.2rem;
      flex-direction: column;

      .img-title {
        color: #FFF;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Montserrat;
        font-size: 0.2rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }

      .img-detail {
        margin-top: 0.12rem;
        color: #FFF;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Nunito Sans;
        font-size: 0.15rem;
        font-style: normal;
        font-weight: 400;
        line-height: 0.25rem; /* 187.5% */
        margin-right: 10px;
      }
    }

    .right {
      margin-top: 0.2rem;
      height: 4rem;
      border-radius: 0.2rem;
      box-sizing: border-box;
      background-image: url('@/assets/img/Mask group-two.png');
      background-size: 100% 100%;
      display: flex;
      justify-content: flex-end;
      padding: 0 0 80px 20px;
      flex-direction: column;

      .img-title {
        color: #FFF;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Montserrat;
        font-size: 0.2rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }

      .img-detail {
        margin-top: 0.12rem;
        color: #FFF;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Nunito Sans;
        font-size: 0.15rem;
        font-style: normal;
        font-weight: 400;
        line-height: 0.25rem; /* 187.5% */
        margin-right: 10px;
      }
    }
  }
}
</style>
