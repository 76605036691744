<template>
  <div
    class="page2"
    id="page2"
    :class="isDay ? 'bg-white-color' : 'bg-black-color'"
  >
    <div class="content">
      <h1 class="page-title" :class="isDay ? 'c_black' : 'c_white'">{{ $t(`nav.company`) }}</h1>
      <div class="flex-between">
        <u-animate-container>
          <u-animate
            name="fadeInLeft"
            delay="0s"
            duration="1.3s"
            :iteration="1"
            :offset="0"
            animateClass="animated"
            :begin="false"
          >
            <div class="right">
              <div
                class="size_36 line-50 mb-80"
                :class="isDay ? 'c_black' : 'c_white'"
              >
                <span>{{ $t(`home.three_title`) }}</span>
              </div>
              <div class="mb-80 fit-div"></div>
              <div class="size_18">
                <span
                  class="second-font-color"
                  :class="isDay ? 'c_0000cc' : 'c_ffffcc'"
                  >{{ $t(`home.four_title`) }}</span
                >
              </div>
            </div>
          </u-animate>
        </u-animate-container>
        <u-animate-container>
          <u-animate
            name="fadeInRight"
            delay="0s"
            duration="1.3s"
            :iteration="1"
            :offset="0"
            animateClass="animated"
            :begin="false"
          >
            <div class="flex-center">
              <div class="left">
                <img
                  src="@/assets/img/img_company.png"
                  alt=""
                  class="img-100"
                />
              </div>
            </div>
          </u-animate>
        </u-animate-container>
      </div>
      <div class="bottom">
        <div class="bottom-box">
          <div class="top">
            <img src="@/assets/img/company_icon_01.png" alt="" />
          </div>
          <div class="box-bottom" :class="isDay ? 'c_0000cc' : 'c_ffffcc'">
            <span>{{ $t(`home.five_title`) }}</span>
          </div>
        </div>
        <div class="bottom-box">
          <div class="top">
            <img src="@/assets/img/company_icon_02.png" alt="" />
          </div>
          <div class="box-bottom" :class="isDay ? 'c_0000cc' : 'c_ffffcc'">
            <span>{{ $t(`home.six_title`) }}</span>
          </div>
        </div>
        <div class="bottom-box">
          <div class="top">
            <img src="@/assets/img/company_icon_03.png" alt="" />
          </div>
          <div class="box-bottom" :class="isDay ? 'c_0000cc' : 'c_ffffcc'">
            <span>{{ $t(`home.seven_title`) }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "pageTwo",
  data() {
    return {
      isDay: this.$store.state.isDay,
    };
  },
};
</script>

<style scoped lang='stylus' rel="stylesheet/stylus">
// 电脑
@media (min-width: 781px) {
  .page2 {
    padding: 50px 0;
  }

  .page2 .left {
    width: 525px;
    height: 413px;
  }

  .fit-div {
    width: 51px;
    height: 5px;
    background-color: rgba(255, 255, 255, 0.8);
  }

  .second-font-color {
    line-height: 30px;
  }

  .page2 .right {
    width: 560px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .c_666 {
      color: #999;
    }
  }

  .page2 .right .btn {
    background: #222640;
    width: 200px;
    height: 60px;
    font-size: 18px;
    color: #7958fa;
    border-radius: 10px;
  }

  .bottom {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    margin-top: 70px;
  }

  .bottom-box {
    display: flex;
    flex-direction: column;
    padding: 0 10px;
    .top {
      flex: 1;
      display: flex;
      justify-content: flex-start;
      align-items: flex-end;

      img {
        height: 44px;
        width: 44px;
      }
    }

    .box-bottom {
      flex: 2;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      margin-top: 24px;

      span {
        font-family: Nunito Sans;
        font-size: 18px;
        line-height: 30px;
      }
    }
  }
}

@media (max-width: 780px) {
  .page2 {
    padding: 0.2rem 0;
  }

  .page2 .left {
    width: 2.6rem;
    height: 2.23rem;
  }

  .fit-div {
    width: 0.5rem;
    height: 0.05rem;
    background-color: #6E6B6A;
    border-radius: 0.23rem;
    opacity: 0.4;
  }

  .page2 .right {
    display: flex;
    flex-direction: column;
    justify-content: center;

    div {
      margin-bottom: 0.3rem;
    }

    span {
      font-size: 0.2rem;
      line-height: 0.28rem;
    }
  }

  .page2 .left {
    width: 3.6rem;
    height: 2.6rem;
  }

  .page2 .right .btn {
    background: #222640;
    width: 200px;
    height: 60px;
    font-size: 18px;
    color: #7958fa;
    border-radius: 10px;
  }

  
  .bottom {
    display: flex;
    flex-direction: column;
    align-items: flex-start
    margin-top: 0.2rem;
  }

  .bottom-box {
    display: flex;
    flex-direction: column;
    margin-bottom: 0.2rem;
    .top {
      flex: 2;
      display: flex;
      justify-content: flex-start;
      align-items: flex-end;

      img {
        height: 0.44rem;
        width: 0.44rem;
      }
    }

    .box-bottom {
      flex: 3;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      margin-top: 0.24rem;

      span {
        font-family: Nunito Sans;
        font-size: 0.18rem;
        line-height: 0.3rem;
      }
    }
  }
}
</style>
