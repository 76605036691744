<template>
  <div class="nav" :class="isDay ? 'bg-E9EAEF': 'bg-black-color'">
    <div class="nav-inner flex-between">
      <div class="logo" @click="$router.push('/pc/home')">
        <img  :src="isDay ? logoBlack : logo" alt="" />
      </div>
      <!-- <span>{{isDay}}</span> -->
      <ul class="flex-center menu" v-if="showMenu || !$store.state.isM">
        <li
          class=" "
          v-for="(item, index) in navArr"
          :key="index"
          @click="scrollGo(item)"
          :class="index % 2 === 0 ? 'bg-65' : ''"
        >
          <span class="size_16" :class="isDay ? 'c_black' : 'c_white'">{{ item.name }}</span>
        </li>
      </ul>
      <div class="language-wrap" @click="changeLang">
        <div class="language-text size_12 mr-10" :class="isDay ? 'c_black' : 'c_white'">
          <span v-if="curLang.text == 'English'">中文繁體</span>
          <span v-else>English</span>
          <!-- {{ curLang.text == 'English' ? '中文繁體' : curLang.text }} -->
        </div>
        <!-- <img
          :src="isDay ? arrowWhite : arrowBlack"
          alt=""
          class="language-wrap-arrow"
        /> -->
        <!-- <div class="language-list" v-show="langShow">
          <div
            class="language-list-item size_12 c_white"
            v-for="item in langs"
            :key="item.id"
            @click.stop="changeLanguage(item)"
          >
            {{ item.text }}
          </div>
        </div> -->
      </div>

      <div
        class="menu-icon"
        @click="showMenu = !showMenu"
        v-if="$store.state.isM"
      >
        <img
        :src="isDay ? menu : menuBlack"
          alt=""
          class="img-100"
          v-if="!showMenu"
        />
        <img src="@/assets/img/close.png" alt="" class="img-100" v-else />
      </div>
    </div>
  </div>
</template>

<script>
import i18n from "@/common/i18n";

export default {
  name: "pcHeader",
  data() {
    return {
      langs: [
        {
          id:1,
          text: '中文繁體',
          value: 'zh'
        },
        {
          id: 2,
          text: "English",
          value: "en",
        }
      ],
      curLang: {
        id: 2,
        text: "English",
        value: "en",
      },
      langShow: false,
      showMenu: false,
      isDay:this.$store.state.isDay,
      logo:require("@/assets/img/starry-logo.png"),
      logoBlack:require("@/assets/img/starry-logo-black.png"),
      arrowWhite:require("@/assets/img/down-arrow-white.png"),
      arrowBlack:require("@/assets/img/down-arrow-black.png"),
      menu:require("@/assets/img/menu.png"),
      menuBlack:require("@/assets/img/menu-white.png"),
    };
  },
  watch:{
    "$store.state.isDay":{
      handler:function(newVal,oldVal){
        this.isDay = newVal
      }
    }
  },
  computed: {
    navArr() {
      return [
        {
          name: this.$t(`nav.home`),
          path: "",
          to: "page1",
        },
        {
          name: this.$t(`nav.company`),
          path: "",
          to: "page2",
        },
        {
          name: this.$t(`nav.products`),
          path: "",
          to: "page3",
        },
        {
          name: this.$t(`nav.business`),
          path: "",
          to: "page4",
        },
        {
          name: this.$t(`nav.contact_us`),
          path: "",
          to: "page5",
        },
        // {
        //   name: this.$t(`nav.news`),
        //   path:'',
        //   to: 'page6'
        // },
        // {
        //   name: this.$t(`nav.nodes`),
        //   path:'',
        //   to: 'page7'
        // },
        // {
        //   name: this.$t(`nav.download`),
        //   path:'/download',
        //   to: ''
        // },
        // {
        //   name: this.$t(`nav.helpCenter`),
        //   path:'',
        //   to: 'page9'
        // },
        // {
        //   name: this.$t(`nav.contact_us`),
        //   path:'',
        //   to: 'page10'
        // },
      ];
    },
  },
  mounted() {
    this.langs.forEach((value) => {
      if (value.value == this.$store.state.area) {
        this.curLang = value;
      }
    });
  },
  methods: {
    changeLanguage(lang) {
      this.curLang = lang;
      this.langShow = false;
      i18n.locale = lang.value;
    },
    changeLang(){
      if(this.curLang.value == 'en'){
        this.curLang = {
          id:1,
          text: '中文繁體',
          value: 'zh'
        }
        i18n.locale = this.curLang.value;
      }else{
        this.curLang = {
          id: 2,
          text: "English",
          value: "en",
        }
        i18n.locale = this.curLang.value;
      }
    },
    scrollGo(data) {
      //
      if (this.$route.path != "/home") {
        this.$router.push("/home");
        this.$common.to(data.to);
        this.showMenu = false;
        return;
      }
      if (data.path) {
        this.showMenu = false;
        this.$router.push(data.path);
      } else {
        this.$common.to(data.to);
        this.showMenu = false;
      }
    },
  },
};
</script>

<style scoped lang='stylus' rel="stylesheet/stylus">
// 电脑
@media (min-width: 781px) {
  /* 导航 */
  .nav {
    height: 60px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10000;
  }

  .nav ul li {
    position: relative;
    height: 48px;
    line-height: 48px;
    display: flex;
    margin-right: 30px;
    cursor: pointer;
  }

  .nav ul li:last-child {
    margin-right: 0;
  }

  .nav ul li.active::after {
    position: absolute;
    bottom: 6px;
    content: '';
    width: 16px;
    height: 2px;
    background-image: linear-gradient(to left, #643afb, #3aabfb 0%);
    left: calc(50% - 8px);
    display: block;
    border-radius: 40px;
  }

  .nav-inner {
    height: 100%;
    padding: 0 15px;
  }

  .nav-inner .logo {
    width: 189.15px;
    height: 36.98px;
    cursor: pointer;
  }

  .nav-inner .logo img {
    width: 100%;
    height: 100%;
    display: block;
  }

  .language-wrap {
    position: relative;
    min-width: 100px;
    cursor: pointer;

    .language-wrap-icon {
      width: 20px;
      height: 20px;
    }

    .language-wrap-arrow {
      width: 10px;
      height: 10px;
    }

    .language-text {
      height: 30px;
      line-height: 30px;
      text-align: center;
    }

    .language-list {
      position: absolute;
      top: 100%;
      left: 0;
      right: 0;
      background: linear-gradient(to left, #643afb, #3aabfb);
      border-radius: 8px;

      .language-list-item {
        min-height: 30px;
        line-height: 30px;
        text-align: center;
        cursor: pointer;
      }
    }
  }
}

// 手机
@media (max-width: 780px) {
  /* 导航 */
  .nav {
    height: 0.44rem;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10000;
    // background: linear-gradient(to right, #02b3f0 0%, #1e4ca7 100%);
  }

  .menu {
    position: fixed;
    width: 3rem;
    top: 0.44rem;
    left: 0;
    bottom: 0;
    height: 100vh;
    // background: linear-gradient(to bottom, #02b3f0 0%, #1e4ca7 100%);
    z-index: 88;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    background-color: #7C7C7C;
  }

  .bg-65{
    background-color: #656565;
  }

  .nav ul {
    // padding: 0.5rem 0;
  }

  .nav ul li {
    position: relative;
    height: 1rem;
    width: 100%
    line-height: 0.4rem;
    cursor: pointer;
    display: flex;
    margin-bottom: 0.1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    span{
      color:#fff;
    }
  }

  .nav ul li:last-child {
    margin-right: 0;
  }

  .nav ul li.active::after {
    position: absolute;
    bottom: 0.06rem;
    content: '';
    width: 0.16rem;
    height: 2px;
    background: #fff;
    left: calc(50% - 0.08rem);
    display: block;
    border-radius: 40px;
  }

  .nav-inner {
    height: 100%;
    padding: 0 0.12rem;
  }

  .nav-inner .logo {
    width: 1rem;
    height: 0.3rem;
  }

  .nav-inner .logo img {
    width: 100%;
    height: 100%;
    display: block;
  }

  .menu-icon {
    height: 0.2rem;
    width: 0.2rem;
  }

  .language-wrap {
    position: relative;
    min-width: 100px;
    cursor: pointer;
    margin-left: auto;

    .language-wrap-icon {
      width: 20px;
      height: 20px;
    }

    .language-wrap-arrow {
      width: 10px;
      height: 10px;
    }

    .language-text {
      height: 30px;
      line-height: 30px;
      text-align: center;
    }

    .language-list {
      position: absolute;
      top: 100%;
      left: 0;
      right: 0;
      background: linear-gradient(to left, #643afb, #3aabfb);
      border-radius: 8px;

      .language-list-item {
        min-height: 30px;
        line-height: 30px;
        text-align: center;
        cursor: pointer;
      }
    }
  }
}
</style>
