<template>
  <div class="page1" id="page1">
    <u-animate-container>
      <u-animate
        name="fadeInLeft"
        delay="0s"
        duration="1.3s"
        :iteration="1"
        :offset="0"
        animateClass="animated"
        :begin="false"
      >
        <div class="page1-left">
          <div class="mb-35 line-80 title">
            {{ $t(`home.one_title`) }}
          </div>
        </div>
      </u-animate>
    </u-animate-container>
  </div>
</template>

<script>
export default {
  name: "pageOne",
  methods: {},
};
</script>

<style scoped lang='stylus' rel="stylesheet/stylus">
@media (min-width: 781px) {
  .page1 {
    // background  linear-gradient(135deg, #00BBF6 0%, #300A78 100%);
    background: url('@/assets/img/header-img.png');
    background-size: 100% 100%;
    width: 100%;
    padding-top: 60px;
    position: relative;
    box-sizing: border-box;
  }

  .page1 .page1-left {
    margin-right: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 60px);
    position: relative;
    z-index: 2;
  }

  .page1 .page1-left .title {
    color: #fff;
    font-family: AG Buch Stencil BQ;
    font-size: 86px;
  }

  .page1 .page1-left>p {
    width: 789px;
  }
}

@media (max-width: 780px) {
  .page1 {
    background: url('@/assets/img/header-img-h5.png');
    background-size: 100% 100%;
    width: 100%;
    height: 100vh;
    padding-top: 60px;
    position: relative;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .page1 .page1-left {
    width: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    position: relative;
    z-index: 2;
  }

  .page1 .page1-left .title {
    color: #fff;
    font-family: AG Buch Stencil BQ;
    font-size: 0.2rem;
    line-height: 0.3rem;
  }

  .page1 .page1-left .title2 {
    font-size: 0.14rem;
    width: 1.71rem;
    margin-bottom: 0.1rem;
  }

  .page1 .page1-left>p {
    width: 789px;
  }
}
</style>
