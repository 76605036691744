import Vue from 'vue'
import VueI18n from 'vue-i18n'

import en from '../data/en.json'
import zh from '../data/zh.json'
import store from '@/store'
Vue.use(VueI18n)

const i18n = new VueI18n({
  local: 'en',
  messages:{
    'en': en,
    'zh': zh
  }
})

// common.isOutside()?i18n.locale = "en":i18n.locale = "tc"
i18n.locale = store.state.area


export default i18n
