<template>
  <div>
    <pageOne></pageOne>
    <pageTwo></pageTwo>
    <pageThree></pageThree>
    <pageFour></pageFour>
    <pageFive></pageFive>
  </div>
</template>

<script>
import pageOne from './components/pageOne'
import pageTwo from '@/pages/home/components/pageTwo'
import pageThree from '@/pages/home/components/pageThree'
import pageFour from '@/pages/home/components/pageFour'
import pageFive from '@/pages/home/components/pageFive'
export default {
  name: 'pageHome',
  components:{
    pageOne,pageTwo,pageThree,pageFour,pageFive
  },
  created(){
    let hours = new Date().getHours();
    if (hours >= 6 && hours <= 19) {
      console.log('nnnn');
      this.$store.state.isDay = true;
    } else {
      console.log('cccc');
      this.$store.state.isDay = false;
    }
  },
}
</script>

<style scoped lang='stylus' rel="stylesheet/stylus">

</style>
