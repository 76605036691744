import Vue from 'vue'
import vueRouter from 'vue-router'
import Router from "vue-router"

const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
Vue.use(vueRouter)

import Home from '@/pages/home/home'

const routes = [
    {path: '*', redirect: '/'},
    {path: '/', component: Home}
  ]

const router = new vueRouter({
  mode:'history',
  base: '/dist/',
  routes
})

router.afterEach((to, from,next) => {
  window.scrollTo(0,0)
})

export default router
